import React from "react"
import { graphql } from 'gatsby'
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { GatsbyImage } from 'gatsby-plugin-image'
import Video from "../components/video"
import { Camera, Target, Youtube, ExternalLink, Users, Image, PenTool, Layers} from 'react-feather';

const primary_color = "#0F4C81"

const Technology = props => (
    <div>
    <ul > 
    {props.items && props.items.map((data, index) => {
      return <li className="technology" key={`content_item_${index}`}><code>{data}</code></li>
    })}
  </ul>
  </div>
  )

const Images = props => (
  
  <div className="section">
    <h4>Images and Screenshots</h4>
    {props.items && props.items.map((data, index) => {
      return <div>
        <p>{data.title}:</p>
        <GatsbyImage image={data.img.childImageSharp.gatsbyImageData} alt={data.title} style={{ float: "none", marginRight: "1rem", maxWidth: 600 }} ></GatsbyImage>
        
        {data.description && (
            <i>&nbsp;&nbsp;{data.description}</i>
        )}
      </div>
    })}
  </div>
  )

  const Videos = props => (
  
    <div className="section">
      <h4>Videos</h4>
      {props.items && props.items.map((data, index) => {
        return <div>
          <Video videoSrcURL={data.url} videoTitle={data.title}></Video>
          <strong>{data.title}</strong>
          {data.description && (
              <i>&nbsp;&nbsp;{data.description}</i>
          )}
        </div>
      })}
    </div>
    )


  export const query = graphql`
  query($slug: String!) {
    projectsYaml(slug: { eq: $slug }) {
      title
      slug
      summary
      description
      contribution
      begin
      end
      links {
        title
        url
      }
      bullets
      users
      tech
      videos {
        title
        url
        description
      }
      images {
        img {
          childImageSharp {
            gatsbyImageData
          }
        }
        title
        description
      } 
    }
  }
`

const projectTemplate = ({ location, data }) => {
  const project = data.projectsYaml
  return (
    <Layout location={location} crumbLabel={project.title}>
    <SEO title={project.title} slug={project.slug} />
    <div className="main-narrow">
        <h2>{project.title}</h2>
        <div className="project_dates">{project.begin} - {project.end}</div>
        <p>{project.summary}</p>
        {project.description && (<p>{project.description}</p>)}
        {project.contribution && (
        <div> <span title="My contribution">
         <Target className = "icon-target" color={primary_color} size={24} />
          <div className="section">
          <h4>My Contribution</h4>
          <ul>
            {project.contribution.map((data, index) => {
              return <li key={`content_item_${index}`}>{data}</li>
            })}
          </ul>
          </div></span>
          </div>
      )}
        {project.videos && (
          <div> <span title="Project videos">
            <Youtube className="icon-youtube" color={primary_color} size={24} />
            <Videos items={project.videos}></Videos>
            </span>
          </div>
        )}

        {project.images && (
          <div> <span title="Project images and screenshots">
            <Image className="icon-image" color={primary_color} size={24} />
            <Images items={project.images}></Images>
            </span>
          </div>
        )}
      
      {project.links && (
        <div>
        <ExternalLink className="icon-externallink" color={primary_color} size={24} />
        <div className="section"><span title="External project links">
        <h4>External Links</h4>
        <ul>
          {project.links.map((data, index) => {
            return <li key={`content_item_${index}`}><a href={data.url}>{data.title}</a></li>
          })}
        </ul></span>
        </div>
        </div>
      )}
      {project.bullets && (
        <div><span title="Highlights">
          <PenTool className = "icon-pentool" color={primary_color} size={24} />
          <div className="section">
            <h4>Highlights</h4>
            <ul>
              {project.bullets.map((data, index) => {
                return <li key={`content_item_${index}`}>{data}</li>
              })}
            </ul>
          </div></span>
          </div>
      )}
      {project.users && (
        <div><span title="Users">
          <Users className = "icon-users" color={primary_color} size={24} />
          <div className="section">
          <h4>Users</h4>
          <ul>
            {project.users.map((data, index) => {
              return <li key={`content_item_${index}`}>{data}</li>
            })}
          </ul>
          </div></span>
        </div>
      )}
      {project.tech && (
        <div><span title="Technology stack">
          <Layers className="icon-tool" color={primary_color} size={24} />
          <div className="section">
            <h4>Technology Stack</h4>
            <Technology items={project.tech}></Technology>
          </div></span>
        </div>
      )}
      <br/>
    </div>
    </Layout>
  )
}
export default projectTemplate
